import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    user: {},
    token: "",
    isAuthenticated: false,
    authState: null
}

const oktaSlice = createSlice(
    {
        name: 'oktaSlice',
        initialState,
        reducers: {
            setOktaUser(state,data) {
                state.user = data.payload
            },
            setOktaToken(state,data) {
                state.token = data.payload
            },
            setisAuthenticated(state,data) {
                state.isAuthenticated = data.payload
            },
            setAuthorizationState(state,data) {
                state.authState = data.payload
            }
        }
    }
)

export const { setOktaToken, setOktaUser, setisAuthenticated, setAuthorizationState } = oktaSlice.actions;

export default oktaSlice.reducer; 