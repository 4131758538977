import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    showOrHideMenu:  'hide',
    showOrHideOrderReprocessSearch: 'show',
    showOrHideAuditSearch: 'show',
    orderReprocessSearchDateSelection: 'Select Date and Time',
    token:'',
    isLoggedIn: false,
    name: '',
    pathName: ''
}

const appSlice = createSlice({
    name: 'appSlice',
    initialState,
    reducers: {
        enableMenu(state) {
            state.showOrHideMenu = 'show'
        },
        disableMenu(state) {
            state.showOrHideMenu = 'hide'
        },
        enableOrderReprocessSearch(state) {
            state.showOrHideOrderReprocessSearch = 'show'
        },
        disableOrderReprocessSearch(state) {
            state.showOrHideOrderReprocessSearch = 'hide'
        },
        enableAuditSearch(state) {
            state.showOrHideAuditSearch = 'show'
        },
        disableAuditSearch(state) {
            state.showOrHideAuditSearch = 'hide'
        },
        setPathName(state, path) {
            state.pathName = path.payload;
        },
        getOrderReprocessSearchDateSelection(state) {
            return state.orderReprocessSearchDateSelection
        },
        setOrderReprocessSearchDateSelection(state, dateRange) {
            state.orderReprocessSearchDateSelection = dateRange.payload
        },
        
        setToken(state, {payload}){
            state.token = payload;
        },
        setIsLoggedIn(state, {payload}){
            state.isLoggedIn = payload;
        },
        setName(state, {payload}){
            state.name = payload;
        }
    }
});

export const { 
    enableMenu,
    disableMenu,
    enableAuditSearch,
    disableAuditSearch,
    enableOrderReprocessSearch,
    setPathName,
    disableOrderReprocessSearch,
    getOrderReprocessSearchDateSelection,
    setOrderReprocessSearchDateSelection,
    setToken,
    setIsLoggedIn,
    setName
} = appSlice.actions;
export default appSlice.reducer;