export const AUTHORIZED_USERS = [
	'navneet.kumar2@nike.com',
	'muthukumar.esakki@nike.com',
	'aditi.ardhapure@nike.com',
	'josemon.george@nike.com',
	'muhammadfurqan.arif@nike.com',
];

export const ORDER_TYPES = [
	'Select Order Type',
	'RSTO',
	'STO',
	'ZCON',
	'ZDIG',
	'ZFIN',
	'ZFRE',
	'ZRFR',
	'ZRST',
	'ZSTA',
	'ZSYS',
	'ZCSG',
	'ZTRD',
];

export const PO_TYPES = [
	'Select PO Type',
	'API',
	'C3PO',
	'CLLB',
	'CONV',
	'DIGI',
	'ECS',
	'EDI',
	'EXTF',
	'GSM',
	'INTF',
	'JES',
	'MANL',
	'NBY',
	'NBYE',
	'NET',
	'NTSB',
	'SMT',
	'UII',
	'WRSO',
	'ZS03',
	'ZS04',
	'ZS05',
	'ZS06',
	'ZS09',
	'ZS10',
];

export const MONTHS = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export const EXCLUDE_AUGMENTED_PAYLOAD_STATUSES = [
	'',
	'In Progress',
	'IN_PROGRESS',
	'Exception',
	'EXCEPTION',
	'DLQ',
	'RECEIVED',
];

export const ORCHESTRATION_TIME_TAKEN_STATUSES = [
	'SUCCESS_IN_OFOA',
	'SUBMITTED_SCPI',
	'FAILED_IN_SCPI',
	'FAILED_IN_S4',
	'STO_IDOC',
	'ACKNOWLEDGED',
];

export const ORDER_SENT_TIME_STATUSES = [
	'SUBMITTED_SCPI',
	'FAILED_IN_SCPI',
	'FAILED_IN_S4',
	'STO_IDOC',
	'ACKNOWLEDGED',
];

export const EXCLUDE_S4_PAYLOAD_STATUSES = [
	'',
	'In Progress',
	'IN_PROGRESS',
	'Exception',
	'EXCEPTION',
	'DLQ',
	'Success In OFOA',
	'RECEIVED',
];

export const ORDER_HEADER_ITEM_SUCCESS_STATUSES = ['200', '202'];

export const SALES_ORG_TYPES = {
	GC: {
		'Select Sales Org': 'Select Sales Org',
		9800: 'NIKE China - 9800',
		9700: 'NIKE Taiwan - 9700',
		9610: 'NIKE Macau - 9610',
		9600: 'NIKE Hong Kong - 9600',
	},
	NA: {
		'Select Sales Org': 'Select Sales Org',
		1020: 'NIKE US - 1020',
	},
};

export const reportType = {
	IN_PROGRESS: 'In Progress',
	SENT_EXCEPTION: 'Exception',
	SENT_DLQ: 'Exception',
	SUBMITTED_SCPI: 'Submitted to SCPI',
};

export const PIE_CHART_LABELS = {
	IN_PROGRESS: 'In Progress in OFOA',
	RECEIVED: 'Received in OFOA',
	Exception: 'Business Exception',
	DLQ: 'Failed in OFOA',
	'Success in OFOA': 'Success in OFOA',
	Submitted: 'Submitted to SCPI',
	'Failed in SCPI': 'Failed in SCPI',
	'STO IDoc Created': 'STO IDoc Created',
	'Failed In S4': 'Failed In S4',
	Acknowledged: 'Acknowledged',
	Miscellaneous: 'Miscellaneous',
	IN_CONTROLLED_ALLOCATION: 'Sent For Controlled Allocation',
};

export const PIE_CHART_REPORT_TYPE = {
	RECEIVED_V2: 'Received in OFOA',
	IN_PROGRESS: 'In Progress in OFOA',
	SENT_EXCEPTION: 'Business Exception',
	DLQ: 'Failed in OFOA',
	SENT_DLQ: 'Failed in OFOA',
	SUCCESS_IN_OFOA: 'Success in OFOA',
	SUBMITTED_SCPI: 'Submitted to SCPI',
	SPLIT: 'Split Orders',
	RECEIVED: 'Orders Received by OFOA',
	ARCHIVED: 'Archived Orders',
	ACKNOWLEDGED: 'Acknowledged',
	FAILED_IN_S4: 'Failed in S4',
	FAILED_IN_SCPI: 'Failed in SCPI',
	STO_IDOC: 'STO IDoc Created',
	MISC: 'Miscellaneous',
	IN_CONTROLLED_ALLOCATION: 'Sent For Controlled Allocation',
};

export const EXCEPTION_KEYS = ['SENT_DLQ', 'SENT_EXCEPTION'];

const ORDER_STATUSES_PROD = [
	{
		type: PIE_CHART_REPORT_TYPE.RECEIVED_V2,
		scope: {
			group: 'overallStatus',
			status: 'Received',
		},
		key: 'RECEIVED_V2',
	},
	{
		type: PIE_CHART_REPORT_TYPE.IN_PROGRESS,
		scope: {
			group: 'overallStatus',
			status: 'IN_PROGRESS',
		},
		key: 'IN_PROGRESS',
	},
	{
		type: PIE_CHART_REPORT_TYPE.SENT_EXCEPTION,
		scope: {
			group: 'overallStatus',
			status: 'Exception',
		},
		key: 'SENT_EXCEPTION',
	},
	{
		type: PIE_CHART_REPORT_TYPE.SENT_DLQ,
		scope: {
			group: 'overallStatus',
			status: 'DLQ',
		},
		key: 'SENT_DLQ',
	},
	{
		type: PIE_CHART_REPORT_TYPE.IN_CONTROLLED_ALLOCATION,
		scope: {
			group: 'overallStatus',
			status: 'IN_CONTROLLED_ALLOCATION',
		},
		key: 'IN_CONTROLLED_ALLOCATION',
	},
	{
		type: PIE_CHART_REPORT_TYPE.SUCCESS_IN_OFOA,
		scope: {
			group: 'overallStatus',
			status: 'SUCCESS_IN_OFOA',
		},
		key: 'SUCCESS_IN_OFOA',
	},
	{
		type: PIE_CHART_REPORT_TYPE.SUBMITTED_SCPI,
		scope: {
			group: 'overallStatus',
			status: 'SUBMITTED_SCPI',
		},
		key: 'SUBMITTED_SCPI',
	},
	{
		type: PIE_CHART_REPORT_TYPE.FAILED_IN_SCPI,
		scope: {
			group: 'overallStatus',
			status: 'Failed In SCP',
		},
		key: 'FAILED_IN_SCPI',
	},
	{
		type: PIE_CHART_REPORT_TYPE.STO_IDOC,
		scope: {
			group: 'overallStatus',
			status: 'STO_IDOC_CREATED',
		},
		key: 'STO_IDOC',
	},
	{
		type: PIE_CHART_REPORT_TYPE.FAILED_IN_S4,
		scope: {
			group: 'overallStatus',
			status: 'Failed In S4',
		},
		key: 'FAILED_IN_S4',
	},
	{
		type: PIE_CHART_REPORT_TYPE.ACKNOWLEDGED,
		scope: {
			group: 'overallStatus',
			status: 'Acknowledged',
		},
		key: 'ACKNOWLEDGED',
	},
	{
		type: PIE_CHART_REPORT_TYPE.MISC,
		scope: {
			group: 'overallStatus',
			status: 'Miscellaneous',
		},
		key: 'MISC',
	},
];

const ORDER_STATUSES_NON_PROD = [
	{
		type: PIE_CHART_REPORT_TYPE.RECEIVED_V2,
		scope: {
			group: 'overallStatus',
			status: 'Received',
		},
		key: 'RECEIVED_V2',
	},
	{
		type: PIE_CHART_REPORT_TYPE.IN_PROGRESS,
		scope: {
			group: 'overallStatus',
			status: 'IN_PROGRESS',
		},
		key: 'IN_PROGRESS',
	},
	{
		type: PIE_CHART_REPORT_TYPE.SENT_EXCEPTION,
		scope: {
			group: 'overallStatus',
			status: 'Exception',
		},
		key: 'SENT_EXCEPTION',
	},
	{
		type: PIE_CHART_REPORT_TYPE.SENT_DLQ,
		scope: {
			group: 'overallStatus',
			status: 'DLQ',
		},
		key: 'SENT_DLQ',
	},
	{
		type: PIE_CHART_REPORT_TYPE.IN_CONTROLLED_ALLOCATION,
		scope: {
			group: 'overallStatus',
			status: 'IN_CONTROLLED_ALLOCATION',
		},
		key: 'IN_CONTROLLED_ALLOCATION',
	},
	{
		type: PIE_CHART_REPORT_TYPE.SUCCESS_IN_OFOA,
		scope: {
			group: 'overallStatus',
			status: 'SUCCESS_IN_OFOA',
		},
		key: 'SUCCESS_IN_OFOA',
	},
	{
		type: PIE_CHART_REPORT_TYPE.SUBMITTED_SCPI,
		scope: {
			group: 'overallStatus',
			status: 'SUBMITTED_SCPI',
		},
		key: 'SUBMITTED_SCPI',
	},
	{
		type: PIE_CHART_REPORT_TYPE.FAILED_IN_SCPI,
		scope: {
			group: 'overallStatus',
			status: 'Failed In SCP',
		},
		key: 'FAILED_IN_SCPI',
	},
	{
		type: PIE_CHART_REPORT_TYPE.STO_IDOC,
		scope: {
			group: 'overallStatus',
			status: 'STO IDoc Created',
		},
		key: 'STO_IDOC',
	},
	{
		type: PIE_CHART_REPORT_TYPE.FAILED_IN_S4,
		scope: {
			group: 'overallStatus',
			status: 'Failed In S4',
		},
		key: 'FAILED_IN_S4',
	},
	{
		type: PIE_CHART_REPORT_TYPE.ACKNOWLEDGED,
		scope: {
			group: 'overallStatus',
			status: 'Acknowledged',
		},
		key: 'ACKNOWLEDGED',
	},
	{
		type: PIE_CHART_REPORT_TYPE.MISC,
		scope: {
			group: 'overallStatus',
			status: 'Miscellaneous',
		},
		key: 'MISC',
	},
];

const ORDER_HISTORY_PROD = [
	{
		type: PIE_CHART_REPORT_TYPE.RECEIVED,
		scope: {
			group: 'received',
		},
		key: 'RECEIVED',
	},
	{
		type: PIE_CHART_REPORT_TYPE.ARCHIVED,
		scope: {
			group: 'overallStatus',
			status: 'HAS_SPLIT',
		},
		key: 'ARCHIVED',
	},
	{
		type: PIE_CHART_REPORT_TYPE.SPLIT,
		scope: {
			group: 'split',
		},
		key: 'SPLIT',
	},
];

const ORDER_HISTORY_NON_PROD = [
	{
		type: PIE_CHART_REPORT_TYPE.RECEIVED,
		scope: {
			group: 'received',
		},
		key: 'RECEIVED',
	},
	{
		type: PIE_CHART_REPORT_TYPE.ARCHIVED,
		scope: {
			group: 'overallStatus',
			status: 'HAS_SPLIT',
		},
		key: 'ARCHIVED',
	},
	{
		type: PIE_CHART_REPORT_TYPE.SPLIT,
		scope: {
			group: 'split',
		},
		key: 'SPLIT',
	},
];

export const ORDER_STATUSES =
	process.env.REACT_APP_ENV === 'PROD'
		? ORDER_STATUSES_PROD
		: ORDER_STATUSES_NON_PROD;
export const ORDER_HISTORY =
	process.env.REACT_APP_ENV === 'PROD'
		? ORDER_HISTORY_PROD
		: ORDER_HISTORY_NON_PROD;

const getApiSource = () => {
	let sources = {
		apiSource: process.env.REACT_APP_DEV_BACKEND_URL,
		apiOrderReprocessSource:
			process.env.REACT_APP_DEV_ORDER_REPROCESS_BACKEND_URL,
		apiOrderArchiveSource: process.env.REACT_APP_DEV_ORDER_ARCHIVE_BACKEND_URL,
	};
	switch (process.env.REACT_APP_ENV) {
		case 'PROD':
			sources = {
				apiSource: process.env.REACT_APP_PROD_BACKEND_URL,
				apiOrderReprocessSource:
					process.env.REACT_APP_PROD_ORDER_REPROCESS_BACKEND_URL,
				apiOrderArchiveSource:
					process.env.REACT_APP_PROD_ORDER_ARCHIVE_BACKEND_URL,
			};
			break;
		case 'OSCAR':
			sources = {
				apiSource: process.env.REACT_APP_OSCAR_BACKEND_URL,
				apiOrderReprocessSource:
					process.env.REACT_APP_OSCAR_ORDER_REPROCESS_BACKEND_URL,
				apiOrderArchiveSource:
					process.env.REACT_APP_OSCAR_ORDER_ARCHIVE_BACKEND_URL,
			};
			break;
		case 'DELTA':
			sources = {
				apiSource: process.env.REACT_APP_DELTA_BACKEND_URL,
				apiOrderReprocessSource:
					process.env.REACT_APP_DELTA_ORDER_REPROCESS_BACKEND_URL,
				apiOrderArchiveSource:
					process.env.REACT_APP_DELTA_ORDER_ARCHIVE_BACKEND_URL,
			};
			break;
		case 'SIERRA':
			sources = {
				apiSource: process.env.REACT_APP_SIERRA_BACKEND_URL,
				apiOrderReprocessSource:
					process.env.REACT_APP_SIERRA_ORDER_REPROCESS_BACKEND_URL,
				apiOrderArchiveSource:
					process.env.REACT_APP_SIERRA_ORDER_ARCHIVE_BACKEND_URL,
			};
			break;
		case 'TEST':
			sources = {
				apiSource: process.env.REACT_APP_TEST_BACKEND_URL,
				apiOrderReprocessSource:
					process.env.REACT_APP_TEST_ORDER_REPROCESS_BACKEND_URL,
				apiOrderArchiveSource:
					process.env.REACT_APP_TEST_ORDER_ARCHIVE_BACKEND_URL,
			};
			break;
		case 'DEV':
		default:
			sources = {
				apiSource: process.env.REACT_APP_DEV_BACKEND_URL,
				apiOrderReprocessSource:
					process.env.REACT_APP_DEV_ORDER_REPROCESS_BACKEND_URL,
				apiOrderArchiveSource:
					process.env.REACT_APP_DEV_ORDER_ARCHIVE_BACKEND_URL,
			};
	}
	return sources;
};

// OPS/COM Dashboard API domain
export const apiSource = getApiSource()['apiSource'];
export const apiOrderReprocessSource =
	getApiSource()['apiOrderReprocessSource'];
export const apiOrderArchiveSource = getApiSource()['apiOrderArchiveSource'];

// OPS Dashbqoard API access credentials
export const opsApiAccessCreds = {
	oktaUrl:
		'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/token',
	// oktaUrl: "https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/token",  // Prod
	clientId: 'nike.move.dashboard',
	grantType: 'client_credentials',
	// clientSecret: "tplHvKW98oNfRJGsnvRAmyTf8AncIOEjtGbZ4gy17V6N-EX-j78FD0DX6-IluKSA", // Prod
	clientSecret:
		'64JsMDY6rbusqSVOilyGqM5oax5XOkDG-zunIkCrO2-6wRo_GAIlTqJ8C_OLK_2o', // Non-Prod
	scope: 'ordermgmt.ops.read',
};

export const orderByTypeToolTipData = (
	<ul>
		<li>
			<b>ZFIN: Financial Orders</b>
			<br />
			Financial Digital Orders fulfilled by Hong Kong for invoice reconciliation
			of orders
		</li>
		<li>
			<b>ZCON: Contract Orders</b>
			<br />
			Wholesale Orders placed against existing Sales Order contracts for
			reserved inventory
		</li>
		<li>
			<b>ZFRE: Nike Elite Pipeline</b>
			<br />
			Orders placed by Nike Contracted Athletes and their Guests
		</li>
		<li>
			<b>ZRFR:</b>
			<br />
			Wholesale Free of Charge Return Orders with mandatory validations specific
			to Returns
		</li>
		<li>
			<b>ZRST :</b>
			<br />
			Wholesale Standard Return Orders with mandatory validations specific to
			Returns
		</li>
		<li>
			<b>ZSTA: Standard orders</b>
			<br />
			Billable orders that include both standalone and contract reference
			varieties
		</li>
		<li>
			<b>STO: STock Transport Orders</b>
			<br />
			Purchase Orders transferred between a Distribution Center to a Digital Hub
			(DH) or Store or DH to Store
		</li>
		<li>
			<b>RSTO: Return STO</b>
			<br />
			Transports from a DH to a DC or Store
		</li>
		<li>
			<b>ZSYS: System Orders</b>
			<br />
			Dummy system sales order used to secure inventory from a contract onto an
			STO.
		</li>
	</ul>
);

export const REGIONS = {
	GC: { title: 'GC', desc: 'Greater China' },
	NA: { title: 'NA', desc: 'North America' },
	// EMEA: {title: 'EMEA', desc: 'Europe, Middle East & Africa'},
	// OTHERS: {title: 'Others', desc: 'All Other Regions'}
};
