import React from "react";
import Routes from "routes/routes";
import "./App.css";
import "styles/dashboard.css";

import Header from '../header/Header';
import QualtricsSurvey from "../../container/qualtricsSurvey";
import SnackbarLoader from "../../container/snackbarLoader";

const App = (props) => {

  return (
    <div className="mainContainer">
      <div className="appContainer">
        <Header />
        <Routes />
      </div>
      <QualtricsSurvey/>
      <SnackbarLoader/>
    </div>
  );
}

export default App