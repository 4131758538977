import axios from "axios";
import { mergeObjects } from "./appUtil";

const initialState = {
  reqHeaders: {
    Accept: "*",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
  reqData: {},
  reqParams: {},
};

export const getSyncCall = async (url, reqHeaders, reqParams) => {
  reqHeaders = mergeObjects(initialState.reqHeaders, reqHeaders);

  try {
    if (reqParams) {
      url = url.concat("?").concat(reqParams);
    }
    const response = await axios.get(url, { headers: reqHeaders });
    return response.data;
  } catch (errors) {
    if (!errors.response) {
      throw errors;
    }
    throw errors.response.data;
  }
};

export const getPaginatedSyncCall = async (url, reqHeaders, reqParams) => {
  reqHeaders = mergeObjects(initialState.reqHeaders, reqHeaders);
  try {
    let response = await axios.get(url + "?" + reqParams, {
      headers: reqHeaders,
    });
    let totalPages = response.data["pageTotal"];
    let count = response.data["count"];

    if (totalPages > 1) {
      let addedParams = "&count=" + count + "&anchor=0";
      let newParams = reqParams.concat(addedParams);
      response = await axios.get(url + "?" + newParams, {
        headers: reqHeaders,
      });
    }
    return response.data;
  } catch (errors) {
    if (!errors.response) {
      throw errors;
    }
    throw errors.response.data;
  }
};

export const postSyncCall = async (url, reqData, reqHeaders) => {
  reqHeaders = mergeObjects(initialState.reqHeaders, reqHeaders);
  reqData = mergeObjects(initialState.reqData, reqData);
  try {
    const response = await axios.post(url, reqData, { headers: reqHeaders });
    return response.data;
  } catch (errors) {
    if (!errors.response) {
      throw errors;
    }
    throw errors.response.data;
  }
};

export const putSyncCall = async (url, reqData, reqHeaders) => {
  reqHeaders = mergeObjects(initialState.reqHeaders, reqHeaders);
  reqData = mergeObjects(initialState.reqData, reqData);
  try {
    const response = await axios.put(url, reqData, { reqHeaders });
    return response.data;
  } catch (errors) {
    if (!errors.response) {
      throw errors;
    }
    throw errors.response.data;
  }
};

export const deleteSyncCall = async (url, reqHeaders, reqParams) => {
  reqHeaders = mergeObjects(initialState.reqHeaders, reqHeaders);
  reqParams = mergeObjects(initialState.reqParams, reqParams);
  try {
    const response = await axios.delete(
      url,
      { reqHeaders },
      { param: reqParams }
    );
    return response.data;
  } catch (errors) {
    if (!errors.response) {
      throw errors;
    }
    throw errors.response.data;
  }
};

export const getAsyncCall = async (url, reqHeaders, reqParams) => {
  reqHeaders = mergeObjects(initialState.reqHeaders, reqHeaders);
  reqParams = mergeObjects(initialState.reqParams, reqParams);
  try {
    const response = await axios.get(url, { params: reqParams } , { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }} );
    return response.data;
  } catch (errors) {
    if (!errors.response) {
      throw errors;
    }
    throw errors.response.data;
  }
};

export const getOrderDetailCall = async (url, reqHeaders, reqParams) => {
  reqHeaders = mergeObjects(initialState.reqHeaders, reqHeaders);
  reqParams = mergeObjects(initialState.reqParams, reqParams);
  try {
    const response = await axios.get(
      url,
      { params: reqParams },
      { reqHeaders }
    );

    return response.data;
  } catch (errors) {
    if (!errors.response) {
      throw errors;
    }
    throw errors.response.data;
  }
};

export const postAsyncCall = async (url, reqData, reqHeaders) => {
  reqHeaders = mergeObjects(initialState.reqHeaders, reqHeaders);
  reqData = mergeObjects(initialState.reqData, reqData);
  try {
    const response = axios.post(url, reqData, { reqHeaders });
    return response.data;
  } catch (errors) {
    if (!errors.response) {
      throw errors;
    }
    throw errors.response.data;
  }
};

export const putAsyncCall = async (url, reqData, reqHeaders) => {
  reqHeaders = mergeObjects(initialState.reqHeaders, reqHeaders);
  reqData = mergeObjects(initialState.reqData, reqData);
  try {
    const response = axios.put(url, reqData, { reqHeaders });
    return response.data;
  } catch (errors) {
    if (!errors.response) {
      throw errors;
    }
    throw errors.response.data;
  }
};

export const deleteAsyncCall = async (url, reqHeaders, reqParams) => {
  reqHeaders = mergeObjects(initialState.reqHeaders, reqHeaders);
  reqParams = mergeObjects(initialState.reqParams, reqParams);
  try {
    const response = axios.delete(url, { reqHeaders }, { param: reqParams });
    return response.data;
  } catch (errors) {
    if (!errors.response) {
      throw errors;
    }
    throw errors.response.data;
  }
};
