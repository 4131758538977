import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchForSearchCriteriaOFOA, createSearchRequest, setGlobalRegion } from "reducer/dashboardSlice";
import { Icon, Menu, MenuItem} from "@nike/eds";
import { useLocation } from 'react-router-dom';
import { REGIONS } from 'constants/Constants';

const ToolbarSlot = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const globalRegion = useSelector((state) => state.dashboardSliceReducer.globalRegion )
    const [region, setRegion] = useState(false);

    const changeRegion = (key) => {
        setRegion(!region);
        dispatch(setGlobalRegion(key));
        dispatch(createSearchRequest());
        dispatch(fetchForSearchCriteriaOFOA());
    }
    useEffect(() => {
        if(!globalRegion) {
            dispatch(setGlobalRegion('GC'));
        }
    });
    return (
        <>
            <h2 className="app-header-region" style={{fontSize:'100%'}}><span>Region:</span></h2>
            <div className="p-relative d-flex app-global-region">
            <Menu
                onClickOutside={() => {
                    setRegion(!region);
                }}
                isDark
                isOpen={region}
                bodySlot={
                    <>
                    {Object.keys(REGIONS).map((key) => {
                    return (
                        <MenuItem key={key} onClick={() => changeRegion(key)}>
                        <div>
                            <p>{REGIONS[key].title}</p>
                            <p className="eds-type--legal-1">{REGIONS[key].desc}</p>
                        </div>
                        </MenuItem>
                    )
                })}
                </>
                }
                placement={"bottom-start"}
            >
            <h2
                className="app-header-region d-flex align-items-center"
                onClick={() => {
                    if(['/','/operations','/business'].includes(location.pathname)){
                        setRegion(!region);
                    }
                }}
            >
                {(globalRegion !== "" && REGIONS[globalRegion]) ? REGIONS[globalRegion].title : ''} {['/','/operations','/business'].includes(location.pathname) ? region ? <Icon name="CaretUp" /> : <Icon name="CaretDown" /> : ''}
            </h2>
            </Menu>
        </div>
        </>
    );
};

export default ToolbarSlot;